// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-adults-index-js": () => import("./../../../src/pages/adults/index.js" /* webpackChunkName: "component---src-pages-adults-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-curriculum-index-js": () => import("./../../../src/pages/curriculum/index.js" /* webpackChunkName: "component---src-pages-curriculum-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-gallery-index-js": () => import("./../../../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onstagebeyond-index-js": () => import("./../../../src/pages/onstagebeyond/index.js" /* webpackChunkName: "component---src-pages-onstagebeyond-index-js" */),
  "component---src-pages-privacypolicy-index-js": () => import("./../../../src/pages/privacypolicy/index.js" /* webpackChunkName: "component---src-pages-privacypolicy-index-js" */)
}

